import React from "react";
import logo from "../assets/images/logo.png";
import { FaTwitter, FaLinkedinIn } from "react-icons/fa6";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaInstagram } from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";
import { FiLinkedin } from "react-icons/fi";
import { SlSocialFacebook } from "react-icons/sl";

const Footer = () => {
  return (
    <div
      className="flex flex-col justify-center items-center"
      style={{ backgroundColor: "#00A15F", borderRadius: "30px" }}
    >
      <div className="flex flex-col sm:flex-row items-center justify-between p-8 text-white w-full max-w-3xl h-15">
        <i></i>
        {/* <nav className="flex flex-row gap-5 font-bold">
          <a href="#contact">Contact Us</a>
          <a href="#team">Team Information</a>
          <a href="#media">Media Assets (images, videos, etc.)</a>
        </nav> */}

        <nav className="flex flex-row gap-3" >
          <a href="https://www.instagram.com/projectgrow_crs?igsh=d3hsYWM4aHVmaWxy" target="_blank">
            <FaInstagram fontSize={26} style={{marginLeft: "100%",}} />
          </a>
          <a href="">
            <FiLinkedin fontSize={26} style={{marginLeft: "100%",}} />
          </a>
          <a href="https://x.com/ProjectGrow_CRS?t=iXRTnjJTef42jEJhxVk6IQ&s=09" target="_blank">
            <RiTwitterXLine fontSize={26} style={{marginLeft: "100%",}} />
          </a>
          <a href="https://www.facebook.com/share/bcJeH6HJVC19n7yg/?mibextid=qi2Omg" target="_blank">
            <SlSocialFacebook fontSize={26} style={{marginLeft: "100%",}} />
          </a>
        </nav>
      </div>

      <div
        className="flex flex-col sm:flex-row items-center justify-between text-[15px] md:text-[32px]
       font-bold text-white w-full max-w-4xl"
      >
        <span className="">
          <p className="flex">
            Before you go check out these&nbsp;<u>links</u>
          </p>
        </span>
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="95"
        height="124"
        fill="none"
        viewBox="0 0 95 124"
      >
        <g fill="#fff" clipPath="url(#clip0_4220_43176)">
          <path d="M61.617 112.459l-1.383-1.33a47.013 47.013 0 002.927-3.273l1.493 1.197a50.561 50.561 0 01-3.045 3.389l.008.017zm5.773-7.009l-1.602-1.065a57.527 57.527 0 002.328-3.709l1.687.922a49.866 49.866 0 01-2.42 3.835l.007.017zm4.505-7.85l-1.753-.789a46.948 46.948 0 001.698-4.028l1.82.655a55.902 55.902 0 01-1.765 4.162zm3.181-8.438l-1.878-.503c.41-1.39.744-2.809 1.036-4.228l1.894.35a55.104 55.104 0 01-1.077 4.372l.025.01zm1.805-8.814l-1.936-.207c.18-1.432.284-2.892.335-4.328l1.944.038a56.208 56.208 0 01-.35 4.48l.007.017zm.34-8.963l-1.942.106a51.43 51.43 0 00-.38-4.317l1.924-.286c.197 1.47.324 2.971.408 4.471l-.01.026zm-1.196-8.895l-1.897.44c-.115-.489-.237-.995-.36-1.5a44.722 44.722 0 00-.777-2.663l1.845-.604c.292.929.576 1.84.807 2.775.13.523.252 1.029.382 1.552zm-2.763-8.52l-1.792.766-.444-.984a60.433 60.433 0 00-1.42-2.913l1.73-.905c.53.987 1.025 1.99 1.484 3.009l.46 1.018-.018.008zm-5.846-6.86a53.1 53.1 0 00-2.483-3.535l1.545-1.175a58.784 58.784 0 012.58 3.657l-1.642 1.052zm-5.252-6.858a54.595 54.595 0 00-3.047-3.094l1.358-1.402a47.862 47.862 0 013.137 3.199l-1.456 1.28.008.017zm-6.312-5.922a49.089 49.089 0 00-3.51-2.573l1.107-1.58a55.829 55.829 0 013.636 2.662l-1.233 1.49zm-7.207-4.854a58.567 58.567 0 00-3.875-1.973l.841-1.75a62.91 62.91 0 014.027 2.028l-.983 1.67-.01.025zm-7.912-3.601a47.125 47.125 0 00-4.174-1.276l.518-1.855c1.455.362 2.913.827 4.327 1.332l-.688 1.807.017-.008zm-8.443-2.198a68.828 68.828 0 00-4.353-.592l.22-1.907c1.532.16 3.038.354 4.49.614l-.357 1.885zm-8.746-.897a76.08 76.08 0 00-4.405-.05l.005-1.914a72.99 72.99 0 014.516.062l-.108 1.92-.008-.018zm-8.846.103c-1.467.08-2.961.193-4.43.316l-.142-1.91a101.37 101.37 0 014.491-.322l.081 1.916zm-8.832.742c-1.471.165-2.952.355-4.433.545l-.22-1.896c1.48-.19 2.962-.38 4.458-.535l.203 1.903-.008-.017z"></path>
          <path d="M71.056 112.255l-.149-1.927-9.544.854-.032-9.46-1.938.021.03 11.52 11.633-1.008z"></path>
        </g>
        <defs>
          <clipPath id="clip0_4220_43176">
            <path
              fill="#fff"
              d="M0 0H53.266V110.738H0z"
              transform="rotate(-24.275 52.68 9.66)"
            ></path>
          </clipPath>
        </defs>
      </svg>

      <div
        className="flex flex-row"
        style={{
          color: "white",
        }}
      >
        <nav className="p-2 flex flex-col gap-5">
          <a href="/">Home</a>

          <a href="">Contact Us</a>
        </nav>

        <nav className="p-2 flex flex-col gap-5">
          <a href="/#about">About</a>

          <a href="/">How to Apply</a>
        </nav>
      </div>

      <img
        src={logo}
        alt="Project Grow"
        className="hero-image rounded-2xl"
        style={{
          marginLeft: "45%",
          marginTop: 3,
          marginBottom: 7,
          width: "22%",
          height: "30%",
          "@media (min-width: 640px)": {
            width: "15%",
            height: "20%",
          },
        }}
      />

      <div className="flex flex-col sm:flex-row items-center justify-between pb-3 text-white w-full max-w-4xl">
        <span className="">
          &copy; {new Date().getFullYear()} Project Grow. All Right Reserved
        </span>
      </div>
    </div>
  );
};

export default Footer;
